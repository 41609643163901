import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { AnimateSharedLayout, AnimatePresence, motion, useAnimation } from 'framer-motion'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import Slider, { NextSlide, CurrentSlide, MobileNav } from '../components/slider'
import Button from '../components/button'
import SEO from '../components/seo'
import AnimatedPattern from '../components/animated-pattern'
import AnimatedArrow from '../components/animated-arrow'

// Assets
import Arrow from '../images/arrow.inline.svg'
import WheelElement from '../images/wheel-element.inline.svg'
import CathedralEelement from '../images/cathedral-element.inline.svg'

// Styles
import '../styles/pages/index.scss'

// variants
const backgroundVariants = {
  open: ({ full = '100%' }) => ({
    height: full,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  }),
  closed: ({ initial = 85, delay = 0 }) => ({
    height: initial,
    transition: {
      delay,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  }),
}
const variantsRevealParent = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}
const patternReveal = {
  open: {
    opacity: 1,
    pathLength: 1,

    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    pathLength: 0,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}
const variantsReveal = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const MotionLink = motion.custom(Link)
const slideTimeout = 7

const HeroSection = ({ items, navbarData }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [isMouseOverTabs, setIsMouseOverTabs] = useState(false)
  const [isPanStarted, setIsPanStarted] = useState(false)
  const controls = useAnimation()
  useEffect(() => {
    controls.start({ width: '100%' })
  }, [controls])

  useEffect(() => {
    if (isMouseOverTabs) {
      controls.set({ width: 0 })
      controls.stop()
    } else {
      controls.start({
        width: '100%',
      })
    }
  }, [controls, isMouseOverTabs])

  useEffect(() => {
    let interval
    if (isMouseOverTabs || isPanStarted) {
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        setCurrentItemIndex(oldIndex => (oldIndex + 1) % items.length)
        controls.set({ width: 0 })
        controls.start({
          width: '100%',
        })
      }, slideTimeout * 1000)
    }
    return () => clearInterval(interval)
  }, [controls, items.length, isMouseOverTabs, isPanStarted])

  const currentItem = items[currentItemIndex]

  return (
    <motion.section
      className="hero-section  overflow-hidden relative px-6 md:px-20 h-screen"
      onPanStart={() => setIsPanStarted(true)}
      onPanEnd={(_, pointInfo) => {
        if (Math.abs(pointInfo.offset.x) > 100) {
          if (pointInfo.velocity.x > 0) {
            setCurrentItemIndex(oldIndex => (oldIndex - 1 + items.length) % items.length)
          } else {
            setCurrentItemIndex(oldIndex => (oldIndex + 1) % items.length)
          }
        }
        setIsPanStarted(false)
      }}
    >
      <div className="mx-auto flex items-center md:items-end h-[85%] lg:px-11 xl:h-full xl:items-center xl:mt-26">
        <div className="relative z-30 flex flex-col max-w-screen-md lg:max-w-screen-lg ">
          <AnimateSharedLayout>
            <ul className="md:flex space-x-16 mb-4 hidden">
              {items.map((item, i) => (
                <li
                  className={`${
                    item.id === currentItem.id ? 'text-tuya-delta' : 'text-white'
                  } text-2xl flex items-center`}
                  key={item.id}
                >
                  <Link
                    className="flex items-center"
                    to={`/${item.pageSlug}`}
                    onMouseOver={() => setCurrentItemIndex(i)}
                    onFocus={() => setCurrentItemIndex(i)}
                    onMouseEnter={() => setIsMouseOverTabs(true)}
                    onMouseLeave={() => setIsMouseOverTabs(false)}
                  >
                    {item.id === currentItem.id && (
                      <motion.span
                        layoutId="arrow"
                        className="bg-white w-10 h-10 text-black rounded-full flex items-center justify-center mr-3 "
                      >
                        <Arrow className="w-3 h-3" />
                      </motion.span>
                    )}
                    {item.tabTitle}
                  </Link>
                </li>
              ))}
            </ul>
          </AnimateSharedLayout>
          <motion.hr
            initial={{ width: 0 }}
            animate={controls}
            style={{ maxWidth: 700 }}
            transition={{
              duration: slideTimeout,
              ease: 'linear',
              type: 'tween',
            }}
            className="bg-tuya-delta border-tuya-delta hidden md:block md:h-px"
          />
          <div className="flex items-center text-white mb-8 md:hidden ">
            <span className="bg-white w-8 h-8 text-black rounded-full flex items-center justify-center mr-5 ">
              <Arrow className="w-2 h-2" />
            </span>
            <ul className="flex justify-between space-x-7 text-xl md:text-2xl font-light">
              {[items[currentItemIndex], items[(currentItemIndex + 1) % 3]].map((item, i) => (
                <li className={`${item.id === currentItem.id ? 'text-tuya-delta' : 'text-white'}`} key={item.id}>
                  <Link to={item.pageSlug}>{item.tabTitle}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <AnimatePresence initial={false} exitBeforeEnter>
              <motion.h1
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
                exit={{
                  y: 20,
                  opacity: 0,
                  transition: { duration: 0.3, delay: 0.15 },
                }}
                transition={{ ease: 'easeInOut' }}
                key={currentItem.id}
                className="text-white leading-none text-3xl md:text-7xl md:leading-snug md:-tracking-widest xl:leading-[100px] xl:mt-8"
                dangerouslySetInnerHTML={{ __html: currentItem.title }}
              />
            </AnimatePresence>
            <AnimatePresence initial={false} exitBeforeEnter>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: { duration: 0.4, delay: 0.2 },
                }}
                exit={{
                  y: 20,
                  opacity: 0,
                  transition: { duration: 0.3 },
                }}
                transition={{ ease: 'easeInOut' }}
                key={currentItem.id}
                className="text-white font-light mb-10 md:text-lg md:mb-0"
              >
                {currentItem.subtitle}
              </motion.p>
            </AnimatePresence>
            <div className="flex md:hidden">
              {items.map((item, i) => (
                <span
                  key={item.id}
                  className={`${item.id === currentItem.id ? 'bg-tuya-yellow' : 'bg-white opacity-30'} ${
                    i < items.length ? 'mr-3' : ''
                  } w-2 h-2 rounded-full lg:inline`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center absolute bottom-20 left-1/2 transform -translate-x-1/2 z-30">
          <div className="opening-hours md:hidden">
            <h6 className="text-tuya-delta mb-1 small-text-spaced text-center">
              <Trans>opening_hours</Trans>
            </h6>
            <p className="text-white whitespace-nowrap">
              {navbarData.scheduleDays}:<b className="text-xs"> {navbarData.scheduleHours}</b>
            </p>
            {navbarData.scheduleDaysSecond !== null && navbarData.scheduleHoursSecond !== null && (
              <p className="text-white mb-5 whitespace-nowrap">
                {navbarData.scheduleDaysSecond}:<b className="text-xs"> {navbarData.scheduleHoursSecond}</b>
              </p>
            )}

            <a href="/reservation" className="flex justify-center mt-5">
              <Button>
                <Trans>reservation</Trans>
              </Button>
            </a>
          </div>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bar-wrapper z-30 hidden md:block">
          <div className="main-bar bg-white w-px" />
          <div className="loading-bar bg-tuya-alpha absolute top-0 w-0.5" />
        </div>
      </div>
      <div className="bg-black img-wrapper h-[inherit] w-full absolute top-0 left-0 z-1">
        <div className="bg-black w-full h-full absolute z-10 opacity-50" />
        <AnimatePresence>
          <motion.img
            initial={{ x: '100%', zIndex: 4, ease: 'easeOut' }}
            animate={{ x: 0, zIndex: 4, ease: 'easeOut' }}
            exit={{ x: '-80%', zIndex: 2, ease: 'easeIn' }}
            transition={{ duration: 0.7 }}
            src={currentItem.image.sizes.src}
            srcSet={currentItem.image.sizes.srcSet}
            alt={currentItem.tabTitle}
            key={currentItem.id}
            className="w-full h-full object-cover bg-gray-100 absolute top-0 left-0"
          />
        </AnimatePresence>
      </div>
    </motion.section>
  )
}

const IntroSection = ({ title, content, firstImage, secondImage, secondImageCaption }) => {
  return (
    <>
      <div className="box-intro col-start-2 col-span-1">
        <div className="text-tuya-epsilon">
          <h2 className="text-3xl leading-10 md:leading-[45px] mb-6">{title}</h2>
          <p className="font-light leading-6.5 md:leading-7 lg:text-lg lg:-mr-[10%] 2xl:mr-0">{content}</p>
          <div className="box-intro-img">
            <img
              className="object-cover w-full h-full"
              src={firstImage.sizes.src}
              srcSet={firstImage.sizes.srcSet}
              alt={firstImage}
            />
            <div className="cathedral-element">
              <CathedralEelement />
            </div>
          </div>
          {/* <div className="cathedral-element">
            <CathedralEelement />
          </div> */}
        </div>
      </div>
      <div className="line-vertical-intro hidden" />
      <div className="card-intro col-start-2 col-span-1">
        <div className="mx-12 mb-15 h-full card-intro-pic ">
          <span className="image-caption !text-left">{secondImageCaption}</span>
          <img
            className="object-cover w-full md:h-[465px] xl:h-auto"
            src={secondImage.sizes.src}
            srcSet={secondImage.sizes.srcSet}
            alt={secondImage}
          />
        </div>
      </div>
    </>
  )
}

const Section2 = ({ data }) => {
  const [isExploreFoodHovered, setIsExploreFoodHovered] = useState(true)
  const [isDrinksHovered, setIsDrinksHovered] = useState(false)
  const {
    mainTitle,
    mainParagraph,
    mainImageFirst,
    mainImageSecond,
    mainImageSecondCaption,
    carousel,
    firstCardTitle,
    firstCardLink,
    firstCardImage,
    secondCardTitle,
    secondCardLink,
    secondCardImage,
    menuImage,
    menuParagraph,
    menuSmallText,
    secondCardSmallText,
  } = data
  return (
    <section className="section-2 pt-14 md:pt-9 lg:pt-24">
      <div className="wrapper grid grid-cols-[7%_86%_7%]">
        <IntroSection
          title={mainTitle}
          content={mainParagraph}
          firstImage={mainImageFirst}
          secondImage={mainImageSecond}
          secondImageCaption={mainImageSecondCaption}
        />

        {carousel.length > 0 && (
          <Slider carousel={carousel}>
            <NextSlide className="slider-item slider-next col-start-1 col-span-1 pr-2.5 h-[420px]" />
            <CurrentSlide className="slider-item slider-current col-start-2 col-span-1 h-[420px]" />
            <MobileNav className="col-start-2 col-span-1 pt-10 pb-14" />
          </Slider>
        )}

        <div className="food-lines hidden" />
        <div className="bottom-lines hidden" />
        <div className="line-food-share hidden" />
        <div className="line-drinks hidden" />
        <div className="line-menu hidden" />
        <div className="line-menu-vertical hidden" />
        <Link to={firstCardLink} className="card-food col-start-2 col-span-1 lg:hidden">
          <div className="bg-tuya-omicron px-4 py-7.5 w-full flex flex-col">
            <div variants={variantsRevealParent}>
              <AnimatedPattern className="text-tuya-iota w-full" />
              <AnimatedPattern className="text-tuya-iota w-full" />
            </div>

            <div className="text-tuya-tau mt-20 mb-36 mx-7.5 text-[4rem] -tracking-[1px] leading-17 font-normal">
              <p>
                <Trans>food</Trans>
              </p>
              <p>
                <Trans>for</Trans>
              </p>
              <p>
                <Trans>sharing</Trans>
              </p>
            </div>
            <div className="text-tuya-tau ml-4">
              <p className="text-lg mb-2.5">{firstCardTitle}</p>
              <Arrow className="w-3 h-3" />
            </div>
          </div>
        </Link>

        <motion.div
          className="card-food-anim hidden lg:block"
          onHoverStart={() => setIsExploreFoodHovered(false)}
          onHoverEnd={() => setIsExploreFoodHovered(true)}
          initial={true}
          animate={isExploreFoodHovered ? 'open' : 'closed'}
        >
          <Link to={firstCardLink}>
            <motion.div className="px-4 py-5 w-full h-full absolute z-10 bottom-0 flex flex-col justify-between">
              <motion.div variants={variantsRevealParent}>
                <AnimatedPattern variants={patternReveal} className="text-tuya-iota w-full" />
                <AnimatedPattern variants={patternReveal} className="text-tuya-iota w-full -mt-2 xl:mt-0" />
              </motion.div>
              <motion.div
                key="explore-food-text"
                variants={variantsRevealParent}
                className="text-tuya-tau lg:text-4xl lg:leading-12 lg:-tracking-[1px] xl:leading-17 xl:text-6xl xl:ml-10 2xl:ml-5"
              >
                <motion.p key="food" variants={variantsReveal}>
                  <Trans>food</Trans>
                </motion.p>
                <motion.p key="for" variants={variantsReveal}>
                  <Trans>for</Trans>
                </motion.p>
                <motion.p key="sharing" variants={variantsReveal}>
                  <Trans>sharing</Trans>
                </motion.p>
              </motion.div>

              <div className="text-tuya-tau">
                <p className="text-lg mb-2">{firstCardTitle}</p>
                <AnimatedArrow isHovered={!isExploreFoodHovered} className="h-3" />
              </div>
            </motion.div>
            <motion.div
              variants={backgroundVariants}
              custom={{ delay: 0.5 }}
              className="bg-tuya-omicron absolute w-full bottom-0"
            />
          </Link>
          <div className="w-full h-full">
            <img
              className="object-cover w-full h-full"
              src={firstCardImage.sizes.src}
              srcSet={firstCardImage.sizes.srcSet}
              alt={firstCardTitle}
            />
          </div>
        </motion.div>

        <MotionLink
          to={secondCardLink}
          onHoverStart={() => setIsDrinksHovered(false)}
          onHoverEnd={() => setIsDrinksHovered(true)}
          initial={true}
          animate={isDrinksHovered ? 'open' : 'closed'}
          className="card-concept col-start-2 col-span-1 ml-5  mt-10 h-[400px] max-w-[72%] overflow-hidden relative"
        >
          <p className="image-caption xl:hidden 2xl:block !text-left">{secondCardSmallText}</p>
          <div className="px-7 py-6 w-full h-full absolute z-10 bottom-0 flex">
            <div className="w-full self-end ">
              <p className="text-white text-lg mb-2">{secondCardTitle}</p>
              <AnimatedArrow isHovered={isDrinksHovered} className="h-3 text-white" />
            </div>
          </div>
          <motion.div
            variants={backgroundVariants}
            custom={{ initial: 0, full: 85 }}
            className="bg-tuya-omicron absolute w-full bottom-0"
          />
          <div className="w-full flex-1 max-h-full md:h-[600px] lg:h-[460px] xl:h-[600px] 3xl:h-[770px]">
            <img
              className="object-cover object-center w-full h-full"
              src={secondCardImage.sizes.src}
              srcSet={secondCardImage.sizes.srcSet}
              alt={secondCardTitle}
            />
          </div>
        </MotionLink>

        <div className="col-start-2 col-span-1 my-15 flex justify-center md:hidden">
          <WheelElement />
        </div>
        <div className="card-menu-text col-start-2 col-span-1 pb-15">
          <div className="hidden justify-center lg:flex h-[70px] lg:mt-6 lg:mb-9 xl:h-[112px] xl:mt-12 xl:mb-10">
            <WheelElement className="max-h-full" />
          </div>
          <p className="text-tuya-epsilon font-light mx-5 md:mx-0">{menuParagraph}</p>
        </div>
        <div className="card-food-second col-start-2">
          <div className="px-5 md:px-0 lg:h-full">
            <img
              className="object-cover w-full h-full"
              src={menuImage.sizes.src}
              srcSet={menuImage.sizes.srcSet}
              alt="Menu"
            />
            <p className="image-caption mt-2 md:mb-0">{menuSmallText}</p>
          </div>
        </div>
        <div className="box-menu-nav col-start-2 col-span-1 mt-10">
          <div className="px-5 md:px-0">
            <Link to="/food" className="text-tuya-alpha text-3xl text-left lg:text-2xl">
              <Trans>check_the_menu</Trans>
              <AnimatedArrow className="h-3 text-tuya-rho mt-2 md:mt-0" />
            </Link>
          </div>
        </div>

        <div className="hidden h-full w-full reservation justify-end items-center">
          <a href="/reservation">
            <Button isDark isVertical>
              <Trans>reservation</Trans>
            </Button>
          </a>
        </div>
      </div>
    </section>
  )
}

const IndexPage = ({ data, location }) => {
  const { sliderItems, ...section2Data } = data.datoCmsHome
  return (
    <Layout className="index-page" pathname={location.pathname}>
      <SEO title="French Riviera culinary experience" />
      <HeroSection items={sliderItems} navbarData={data.datoCmsResturantInformation} />
      <Section2 data={section2Data} />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsHome(locale: { eq: $language }) {
      sliderItems {
        id
        title
        tabTitle
        subtitle
        pageSlug
        image {
          sizes {
            srcSet
            src
          }
        }
      }
      carousel {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
        title
      }
      firstCardImage {
        sizes {
          src
          srcSet
        }
      }
      firstCardTitle
      firstCardLink
      mainParagraph
      mainTitle
      mainImageFirst {
        sizes {
          src
          srcSet
        }
      }
      mainImageSecond {
        sizes {
          src
          srcSet
        }
      }
      mainImageSecondCaption
      menuImage {
        sizes {
          srcSet
          src
        }
      }
      menuParagraph
      menuSmallText
      secondCardSmallText
      secondCardLink
      secondCardTitle
      secondCardImage {
        sizes {
          src
          srcSet
        }
      }
    }
    datoCmsResturantInformation(locale: { eq: $language }) {
      scheduleDays
      scheduleHours
      scheduleDaysSecond
      scheduleHoursSecond
    }
  }
`

export default IndexPage
